import { useRouter } from '#imports'

import { authLegacyAPI, oauthAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import { clearAuthStorages } from '../utils/clearAuthStorage'

import { useAuthOrigin } from './useAuthOrigin'
import { useAuthStore } from './useAuthStore'
import { useUserStore } from './useUserStore'

function clearStores() {
  const authStore = useAuthStore()
  const userStore = useUserStore()
  authStore.$reset()
  userStore.$reset()
}

export function useDeleteClientAccount() {
  const router = useRouter()

  /**
   * Delete the current client account.
   *
   * This functions takes care of deleting the client account, and clears
   * the different internal storages. In the end, people get redirect to
   * the home page.
   *
   * @example
   * const { deleteClientAccount } = useDeleteClientAccount()
   *
   * deleteClientAccount(false)
   */
  async function deleteClientAccount(force: boolean) {
    await $httpFetch(authLegacyAPI.deleteClientAccount, {
      queryParams: {
        force,
      },
    })
    await $httpFetch(oauthAPI.getLogout, { baseURL: useAuthOrigin() })

    await clearAuthStorages()
    clearStores()

    const redirect = router.resolve({ name: 'home' })
    await router.push(redirect.href)
  }

  return { deleteClientAccount }
}
