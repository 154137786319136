export default {
  /* Main page */
  title: {
    id: 'delete_account_title',
    defaultMessage: 'Delete account',
  },
  subtitle: {
    id: 'delete_account_subtitle',
    defaultMessage: 'There will be no way back',
  },
  description1: {
    id: 'delete_account_description_1',
    defaultMessage: 'By deleting your account, you’re willing to:',
  },
  bulletPoint1: {
    id: 'delete_account_bullet_point_1',
    defaultMessage: 'Lose your account information',
  },
  bulletPoint2: {
    id: 'delete_account_bullet_point_2',
    defaultMessage: 'Stop receiving follow-up emails and texts',
  },
  bulletPoint3: {
    id: 'delete_account_bullet_point_3',
    defaultMessage: 'Have to re-register to place a new order',
  },
  bulletPoint4: {
    id: 'delete_account_bullet_point_4',
    defaultMessage:
      'delete all customer service messages and order information,',
  },
  description2: {
    id: 'delete_account_description_2',
    defaultMessage:
      'If you select “Delete account”, you will be logged out automatically.',
  },
  confirmationCheckbox: {
    id: 'delete_account_confirmation_checkbox',
    defaultMessage: 'Yes, I want to delete my Back Market account.',
  },
  deleteButton: {
    id: 'delete_account_delete_account_button',
    defaultMessage: 'Delete account',
  },
  keepItButton: {
    id: 'delete_account_keep_account_button',
    defaultMessage: 'Keep it',
  },
  legalFooter: {
    id: 'delete_account_legal_footer',
    defaultMessage:
      'Please note that order-related data may be retained for a limited period if you’ve made a purchase or Trade-in. For more details about our data privacy practices, {link, html}.',
  },
  legalFooterLink: {
    id: 'delete_account_legal_footer_link',
    defaultMessage: 'see our Privacy Policy',
  },
  /* Confirmation toast */
  confirmationToastTitle: {
    id: 'delete_account_confirmation_toast_title',
    defaultMessage: 'So long, partner.',
  },
  confirmationToastContent: {
    id: 'delete_account_confirmation_toast_content',
    defaultMessage: 'Your account has been deleted.',
  },
  /* Rejection modal */
  rejectionModalHeader: {
    id: 'delete_account_rejection_modal_header',
    defaultMessage: 'Contact support',
  },
  rejectionModalTitle: {
    id: 'delete_account_rejection_modal_title',
    defaultMessage: 'Your account can’t be deleted just yet.',
  },
  rejectionModalContent1: {
    id: 'delete_account_rejection_modal_content_1',
    defaultMessage: 'This may be because you still have: ',
  },
  rejectionModalReason1: {
    id: 'delete_account_rejection_modal_reason_1',
    defaultMessage: 'Money in your wallet',
  },
  rejectionModalReason2: {
    id: 'delete_account_rejection_modal_reason_2',
    defaultMessage: 'An order within the 30-day return period',
  },
  rejectionModalReason3: {
    id: 'delete_account_rejection_modal_reason_3',
    defaultMessage: 'A customer service request in progress',
  },
  rejectionModalReason4: {
    id: 'delete_account_rejection_modal_reason_4',
    defaultMessage: 'An ongoing Trade-in',
  },
  rejectionModalReason5: {
    id: 'delete_account_rejection_modal_reason_5',
    defaultMessage: 'An ongoing insurance plan',
  },
  rejectionModalContent2: {
    id: 'delete_account_rejection_modal_content_2',
    defaultMessage:
      'If you still want to delete your account, reach out to our legal team for help.',
  },
  rejectionModalButtonClose: {
    id: 'delete_account_rejection_modal_button',
    defaultMessage: 'Close',
  },
  rejectionModalButtonEmailLegal: {
    id: 'delete_account_rejection_modal_email_legal_button',
    defaultMessage: 'Email the legal team',
  },
  rejectionModalButtonBackToProfile: {
    id: 'delete_account_rejection_modal_back_to_profile_button',
    defaultMessage: 'Go back to Profile',
  },
  emailSubject: {
    id: 'delete_account_email_subject',
    defaultMessage: 'Anonymization request',
  },
  /* Warranty warning modal */
  warrantyModalTitle: {
    id: 'delete_account_warranty_confirmation_modal_title',
    defaultMessage: 'Orders under warranty',
  },
  warrantyModalHeader: {
    id: 'delete_account_warranty_confirmation_modal_header',
    defaultMessage:
      'Your have an ongoing 1-year warranty for one or more orders.',
  },
  warrantyModalContent1: {
    id: 'delete_account_warranty_confirmation_modal_content_1',
    defaultMessage:
      'Once your account’s deleted, you won’t have access to order information, including 1-year warranty details.',
  },
  warrantyModalContent3: {
    id: 'delete_account_warranty_confirmation_modal_content_3',
    defaultMessage:
      'So you can easily get help later, {link, html} > “See order details” > save all order details and documents (including proof of purchase and receipt). Make sure to do so for any orders with an ongoing 1-year warranty. Please note receipts can take up to 24 business hours to become available after requesting.',
  },
  warrantyModalContent3Link: {
    id: 'delete_account_warranty_confirmation_modal_content_3_link',
    defaultMessage: 'go to "Orders"',
  },
  warrantyModalContent2: {
    id: 'delete_account_warranty_confirmation_modal_content_2',
    defaultMessage:
      'Once that’s done, you can go ahead with deleting your account (even if we’ll miss you).',
  },
  warrantyModalDeleteButton: {
    id: 'delete_account_warranty_confirmation_modal_delete_button',
    defaultMessage: 'Delete',
  },
  warrantyModalButton2: {
    id: 'delete_account_warranty_confirmation_modal_button_2',
    defaultMessage: 'Keep it',
  },
}
