<template>
  <!-- Main page -->
  <RevContainer class="my-24 space-x-12">
    <RevCard class="p-24 lg:p-32">
      <h2 class="body-2 mb-4">{{ i18n(translations.subtitle) }}</h2>
      <h1 class="heading-1 mb-24">{{ i18n(translations.title) }}</h1>

      <p class="body-1">{{ i18n(translations.description1) }}</p>
      <ul class="list-disc pl-20">
        <li>{{ i18n(translations.bulletPoint1) }}</li>
        <li>{{ i18n(translations.bulletPoint4) }}</li>
        <li>{{ i18n(translations.bulletPoint2) }}</li>
        <li>{{ i18n(translations.bulletPoint3) }}</li>
      </ul>
      <p class="mt-20">{{ i18n(translations.description2) }}</p>

      <RevCheckbox
        id="delete-account-checkbox"
        v-model="allowDeleteAccount"
        class="body-1 my-24"
        data-qa="delete-account-page-checkbox"
      >
        <template #label>{{
          i18n(translations.confirmationCheckbox)
        }}</template>
      </RevCheckbox>

      <p class="caption">
        <FormattedMessage
          class="text-static-default-low"
          :definition="translations.legalFooter"
        >
          <template #link>
            <RevLink
              target="_blank"
              :to="
                resolveLocalizedRoute({
                  name: CMS.LEGAL_PAGE,
                  params: {
                    pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
                    locale: currentLocale,
                  },
                })
              "
            >
              {{ i18n(translations.legalFooterLink) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </p>

      <div class="flex flex-col lg:flex-row">
        <RevButton
          class="body-1 mt-24"
          variant="secondary"
          @click="navigateTo({ name: ROUTE_NAMES.DASHBOARD.KYC.PROFILE })"
        >
          {{ i18n(translations.keepItButton) }}
        </RevButton>
        <RevButton
          class="body-1 mt-12 lg:ml-10 lg:mt-24"
          data-qa="delete-account-page-submit-button"
          :disabled="!allowDeleteAccount"
          variant="primaryDestructive"
          @click="deleteAccount({ force: false })"
        >
          {{ i18n(translations.deleteButton) }}
        </RevButton>
      </div>
    </RevCard>
  </RevContainer>
  <!-- Warranty warning modal -->
  <RevDialog
    :close-button-label="i18n(translations.warrantyModalButton2)"
    :name="WARRANTY_WARNING_MODAL"
    :title="i18n(translations.warrantyModalTitle)"
  >
    <template #body>
      <p class="heading-1 my-20">
        {{ i18n(translations.warrantyModalHeader) }}
      </p>
      <p class="mb-20">{{ i18n(translations.warrantyModalContent1) }}</p>
      <p class="mb-20">
        <FormattedMessage :definition="translations.warrantyModalContent3">
          <template #link>
            <RevLink
              target="_self"
              :to="{ name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF }"
            >
              {{ i18n(translations.warrantyModalContent3Link) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </p>
      <p>{{ i18n(translations.warrantyModalContent2) }}</p>
    </template>
    <template #footer>
      <RevButton
        class="mb-12"
        :disabled="isDisabling"
        full-width="always"
        :loading="isDisabling"
        variant="primaryDestructive"
        @click="deleteAccount({ force: true })"
      >
        {{ i18n(translations.warrantyModalDeleteButton) }}
      </RevButton>
      <RevButton
        class="mb-12"
        full-width="always"
        :to="{ name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF }"
        variant="secondary"
      >
        {{ i18n(translations.warrantyModalButton2) }}
      </RevButton>
    </template>
  </RevDialog>

  <!-- Rejection modal -->
  <RevDialog
    :close-button-label="i18n(translations.rejectionModalButtonClose)"
    :name="DELETE_ACCOUNT_REJECTION_MODAL"
    :title="i18n(translations.rejectionModalHeader)"
  >
    <template #body>
      <p class="heading-1 my-20">
        {{ i18n(translations.rejectionModalTitle) }}
      </p>
      <p>{{ i18n(translations.rejectionModalContent1) }}</p>
      <ul class="mb-20 list-disc pl-20">
        <li>{{ i18n(translations.rejectionModalReason1) }}</li>
        <li>{{ i18n(translations.rejectionModalReason2) }}</li>
        <li>{{ i18n(translations.rejectionModalReason3) }}</li>
        <li>{{ i18n(translations.rejectionModalReason4) }}</li>
        <li>{{ i18n(translations.rejectionModalReason5) }}</li>
      </ul>
      <p>{{ i18n(translations.rejectionModalContent2) }}</p>
    </template>
    <template #footer>
      <RevButton
        class="mb-12"
        full-width="always"
        variant="primary"
        @click="openMailTo"
      >
        {{ i18n(translations.rejectionModalButtonEmailLegal) }}
      </RevButton>
      <RevButton
        class="mb-12"
        full-width="always"
        variant="secondary"
        @click="navigateTo({ name: ROUTE_NAMES.DASHBOARD.KYC.PROFILE })"
      >
        {{ i18n(translations.rejectionModalButtonBackToProfile) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { navigateTo, useRouteLocationWithLocale } from '#imports'
import { ref } from 'vue'

import { HttpApiError } from '@backmarket/http-api'
import { useDeleteClientAccount } from '@backmarket/nuxt-layer-oauth/useDeleteAccount'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevContainer } from '@ds/components/Container'
import { RevDialog } from '@ds/components/Dialog'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { CMS } from '~/scopes/cms/routes-names'
import { ROUTE_NAMES } from '~/scopes/dashboard/constants'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import {
  DELETE_ACCOUNT_REJECTION_MODAL,
  WARRANTY_ERROR,
  WARRANTY_WARNING_MODAL,
} from './DeleteAccount.constants'
import translations from './DeleteAccount.translations'

const i18n = useI18n()
const currentLocale = useI18nLocale()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const allowDeleteAccount = ref(false)
const isDisabling = ref(false)
const email = 'legal+account@backmarket.com'
const emailSubject = i18n(translations.emailSubject)
const mailTo = `mailto:${email}?subject=${emailSubject}`
const tracking = useTracking()
const { openSuccessToast } = useTheToast()
const { deleteClientAccount } = useDeleteClientAccount()

interface DeleteAccountParams {
  force: boolean
}

function openMailTo() {
  window.open(mailTo, '_self')
}

async function deleteAccount({ force = false }: DeleteAccountParams) {
  tracking.trackClick({
    page_type: 'anonymization',
    name: force
      ? 'confirm_anonymize_account_with_warranty'
      : 'confirm_anonymize_account',
  })
  isDisabling.value = true
  try {
    await deleteClientAccount(force)
    openSuccessToast({
      title: i18n(translations.confirmationToastTitle),
      content: i18n(translations.confirmationToastContent),
    })
    tracking.trackToastView({
      name: force
        ? 'anonymization_performed_with_warranty'
        : 'anonymization_performed',
    })
  } catch (error) {
    const { title } = HttpApiError.fromAnyError(error as Error)
    if (title === WARRANTY_ERROR) {
      tracking.trackModal({
        page_type: 'anonymization',
        name: 'anonymization_ongoing_warranty',
        status: 'opened',
      })
      openModal(WARRANTY_WARNING_MODAL)
    } else {
      tracking.trackModal({
        page_type: 'anonymization',
        name: 'anonymization_not_elligible',
        status: 'opened',
      })
      openModal(DELETE_ACCOUNT_REJECTION_MODAL)
    }
  } finally {
    isDisabling.value = false
  }
}
</script>
